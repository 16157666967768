import { Injectable } from '@angular/core';
import { BaseService } from '../classes/base.service';
import { environment } from '../../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { HttpService } from './http.service';
import { AppUserService } from '../../logic/services/appuserservice';
@Injectable()
export class ConfigService extends BaseService {
  private static AppConfig: any;

  constructor(private http: HttpClient, private _httpServ: HttpService, private appUserServ: AppUserService) {
    super();

  }

  load(): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      Promise.all([
        this.getLoggedInUserDetails(),
        this.loadAppConfig()
      ])
        .then(() => {
          resolve();
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  static getConfigData(key: string) {
    return ConfigService.AppConfig[key];
  }

  loadAppConfig(): Promise<void> {
    let jsonFile = ''
    if (environment.isdev) {
      jsonFile = './config.json';
    }
    else {
      jsonFile = environment.apiUrl.replace("/api", "") + "/config.json";
    }
    return new Promise((resolve, reject) => {
      this.http.get(jsonFile).toPromise().then((initdata: any) => {
        ConfigService.AppConfig = initdata.referenceData;
        resolve();
      }).catch(err => {
        reject(`Could not load file`);
      })
    });
  }

  static getRefCodes(types: string[]): Promise<any[]> {
    return new Promise((resolve, reject) => {
      let refcodes: any = {};
      types.forEach(x => {
        refcodes[x] = ConfigService.AppConfig[x];
      });
      resolve(refcodes);
    });
  }

  getLoggedInUserDetails(): Promise<void> {
    return new Promise((resolve, reject) => {
      if (this.appUserServ.isLogin()) {
        this._httpServ.Get(false, true, '/user/getLoggedInUserDetails')
          .then((res: any) => {
            this.appUserServ.setLoginData(res.data);
            resolve();
          })
          .catch(err => {
            reject(err);
          });
      } else {
        resolve();
      }
    });
  }

}
